import React from "react";
import { Helmet } from "react-helmet";
import { useI18next } from "gatsby-plugin-react-i18next";
import Header from "components/Header";
import Footer from "components/Footer";
import Breadcrumb from "components/Breadcrumb";

export default function Layout({ title, crumbs, children }) {
	const { language } = useI18next();
	return (
		<>
			<Helmet>
				<title>{title}</title>
				<meta name="WT.cg_n" content="HTML public" />
				<meta name="WT.cg_s" content="DGT" />
				<meta name="DCSext.lang" content={language} />
				<meta name="catalogueNumber" content={`HC-03-21-419-${language.toUpperCase()}-Q`} />
				<html lang={language} />
			</Helmet>
			<div className="Layout">
				<Header />
				{crumbs && <Breadcrumb crumbs={crumbs} />}
				<main>{children}</main>
				<Footer />
			</div>
		</>
	);
}
