import React from "react";
import "components/Title.scss";

export default function Title({ title, content }) {
	return (
		<div className="Title">
			<h1>{ title }</h1>
			{content && <p>{ content }</p>}
		</div>
	);
}