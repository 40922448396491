import React, { Fragment } from "react";
import { Link, withAssetPrefix } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import "components/Breadcrumb.scss";

export default function Breadcrumb({ crumbs }) {
	const { t } = useTranslation();
	const length = crumbs.length - 1;

	return (
		<section className="Breadcrumb">
			<nav className="ecl-breadcrumb-harmonised" aria-label="You are here:">
				<ol className="ecl-breadcrumb-harmonised__container">
					{crumbs.map((crumb, index) => (
						<li
							key={crumb.pathname}
							className={`ecl-breadcrumb-harmonised__segment ${
								index === length ? "ecl-breadcrumb-harmonised__current-page" : ""
							}`}
							aria-current={index === length ? "page" : false}
						>
							{index === length ? (
								t(crumb.label)
							) : (
								<Fragment>
									<Link
										to={crumb.pathname}
										className="ecl-link ecl-link--standalone ecl-link--no-visited ecl-breadcrumb-harmonised__link"
									>
										{t(crumb.label)}
									</Link>
									<svg
										className="ecl-icon ecl-icon--2xs ecl-icon--rotate-90 ecl-breadcrumb-harmonised__icon"
										aria-hidden="true"
									>
										<use xlinkHref={withAssetPrefix("/images/ec-icons.svg#corner-arrow")}></use>
									</svg>
								</Fragment>
							)}
						</li>
					))}
				</ol>
			</nav>
		</section>
	);
}
