import React from "react";
import { withAssetPrefix } from "gatsby";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";

export default function Footer() {
	const { t } = useTranslation();
	const { language } = useI18next();

	return (
		<footer className="Footer ecl-footer-harmonised ecl-footer-harmonised--group1">
			<div className="ecl-container ecl-footer-harmonised__container">
				{/* First row */}
				<div className="ecl-footer-harmonised__row">
					<div className="ecl-footer-harmonised__column">
						<div className="ecl-footer-harmonised__section">
							<h2 className="ecl-footer-harmonised__title">
								<a
									href={`https://ec.europa.eu/info/departments/translation_${language}`}
									className="ecl-link ecl-link--standalone ecl-footer-harmonised__title-link"
									rel="external noopener noreferrer"
								>
									{t('footer.directorate.heading')}
								</a>
							</h2>
							<p className="ecl-footer-harmonised__description">
								{t('footer.directorate.content')}
							</p>
						</div>
					</div>

					<div className="ecl-footer-harmonised__column">
						<div className="ecl-footer-harmonised__section">
							<h2 className="ecl-footer-harmonised__title ecl-footer-harmonised__title--separator">
							{t('footer.directorate.contact.heading')}
							</h2>
							<ul className="ecl-footer-harmonised__list">
								<li className="ecl-footer-harmonised__list-item">
									<a
										href={`https://ec.europa.eu/info/departments/translation_${language}#contact`}
										className="ecl-link ecl-link--standalone ecl-footer-harmonised__link"
										rel="external noopener noreferrer"
									>
										{t('footer.directorate.contact.content')}
									</a>
								</li>
							</ul>
						</div>

						<div className="ecl-footer-harmonised__section">
							<h2 className="ecl-footer-harmonised__title ecl-footer-harmonised__title--separator">
							{t('footer.directorate.follow')}
							</h2>
							<ul className="ecl-footer-harmonised__list ecl-footer-harmonised__list--inline">
								<li className="ecl-footer-harmonised__list-item">
									<a
										href="http://www.facebook.com/translatingforeurope"
										className="ecl-link ecl-link--standalone ecl-link--icon ecl-link--icon-before ecl-footer-harmonised__link"
										rel="external noopener noreferrer"
									>
										<svg className="ecl-icon ecl-icon--xs ecl-link__icon" aria-hidden="true">
											<use xlinkHref={withAssetPrefix("/images/ec-icons-social-media.svg#facebook-negative")}></use>
										</svg>
										<span className="ecl-link__label">Facebook</span>
									</a>
								</li>
								<li className="ecl-footer-harmonised__list-item">
									<a
										href="https://twitter.com/translatores"
										className="ecl-link ecl-link--standalone ecl-link--icon ecl-link--icon-before ecl-footer-harmonised__link"
										rel="external noopener noreferrer"
									>
										<svg className="ecl-icon ecl-icon--xs ecl-link__icon" aria-hidden="true">
											<use xlinkHref={withAssetPrefix("/images/ec-icons-social-media.svg#twitter-negative")}></use>
										</svg>
										<span className="ecl-link__label">Twitter</span>
									</a>
								</li>
								<li className="ecl-footer-harmonised__list-item">
									<a
										href="https://www.linkedin.com/company/european-commission"
										className="ecl-link ecl-link--standalone ecl-link--icon ecl-link--icon-before ecl-footer-harmonised__link"
										rel="external noopener noreferrer"
									>
										<svg className="ecl-icon ecl-icon--xs ecl-link__icon" aria-hidden="true">
											<use xlinkHref={withAssetPrefix("/images/ec-icons-social-media.svg#linkedin-negative")}></use>
										</svg>
										<span className="ecl-link__label">Linkedin</span>
									</a>
								</li>
								<li className="ecl-footer-harmonised__list-item">
									<a
										href="https://www.instagram.com/translatingforeurope/"
										className="ecl-link ecl-link--standalone ecl-link--icon ecl-link--icon-before ecl-footer-harmonised__link"
										rel="external noopener noreferrer"
									>
										<svg className="ecl-icon ecl-icon--xs ecl-link__icon" aria-hidden="true">
											<use xlinkHref={withAssetPrefix("/images/ec-icons-social-media.svg#instagram-negative")}></use>
										</svg>
										<span className="ecl-link__label">Instagram</span>
									</a>
								</li>
							</ul>
						</div>
					</div>

					<div className="ecl-footer-harmonised__column">
						<div className="ecl-footer-harmonised__section">
							<h2 className="ecl-footer-harmonised__title ecl-footer-harmonised__title--separator">
								{t('footer.directorate.about.heading')}
							</h2>
							<ul className="ecl-footer-harmonised__list">
								<li className="ecl-footer-harmonised__list-item">
									<a
										href={`https://ec.europa.eu/info/departments/translation_${language}#responsibilities`}
										className="ecl-link ecl-link--standalone ecl-footer-harmonised__link"
										rel="external noopener noreferrer"
									>
										{t('footer.directorate.about.content')}
									</a>
								</li>
							</ul>
						</div>

						<div className="ecl-footer-harmonised__section">
							<h2 className="ecl-footer-harmonised__title ecl-footer-harmonised__title--separator">
								{t('footer.directorate.related-sites.heading')}
							</h2>
							<ul className="ecl-footer-harmonised__list">
								<li className="ecl-footer-harmonised__list-item">
									<a
										href={`https://ec.europa.eu/info/publications/translation_${language}`}
										className="ecl-link ecl-link--standalone ecl-footer-harmonised__link"
										rel="external noopener noreferrer"
									>
										{t('footer.directorate.related-sites.content')}
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>

				{/* Second row */}
				<div className="ecl-footer-harmonised__row">
					<div className="ecl-footer-harmonised__column">
						<div className="ecl-footer-harmonised__section">
							<a
								href={`https://ec.europa.eu/info/index_${language}`}
								className="ecl-link ecl-link--standalone ecl-footer-harmonised__logo-link"
								rel="external noopener noreferrer"
							>
								<img
									alt={`${t('EC')} logo`}
									className="ecl-footer-harmonised__logo-image-desktop"
									src={withAssetPrefix(`/images/ec-logo/negative/logo-ec--${language}.svg`)}
								></img>
							</a>

							<h2 className="ecl-footer-harmonised__title">
								<a
									href={`https://ec.europa.eu/info/index_${language}`}
									className="ecl-link ecl-link--standalone ecl-footer-harmonised__title-link"
									rel="external noopener noreferrer"
								>
									{t('EC')}
								</a>
							</h2>
						</div>
					</div>

					<div className="ecl-footer-harmonised__column">
						<div className="ecl-footer-harmonised__section ecl-footer-standardised__section--split-list">
							<ul className="ecl-footer-harmonised__list">
								<li className="ecl-footer-harmonised__list-item">
									<a
										href={`https://ec.europa.eu/info/about-european-commission/contact_${language}`}
										className="ecl-link ecl-link--standalone ecl-footer-harmonised__link"
										rel="external noopener noreferrer"
									>
										{t('footer.ec.contact')}
									</a>
								</li>
								<li className="ecl-footer-harmonised__list-item">
									<a
										href={`https://european-union.europa.eu/contact-eu/social-media-channels_${language}#/search?page=0&institutions=european_commission`}
										className="ecl-link ecl-link--standalone ecl-footer-harmonised__link"
										rel="external noopener noreferrer"
									>
										{t('footer.ec.follow')}
									</a>
								</li>
								<li className="ecl-footer-harmonised__list-item">
									<a
										href={`https://ec.europa.eu/info/resources-partners_${language}`}
										className="ecl-link ecl-link--standalone ecl-footer-harmonised__link"
										rel="external noopener noreferrer"
									>
										{t('footer.ec.resources')}
									</a>
								</li>
							</ul>
						</div>
					</div>

					<div className="ecl-footer-harmonised__column">
						<div className="ecl-footer-harmonised__section ecl-footer-standardised__section--split-list">
							<ul className="ecl-footer-harmonised__list">
								<li className="ecl-footer-harmonised__list-item">
									<a
										href={`https://ec.europa.eu/info/languages-our-websites_${language}`}
										className="ecl-link ecl-link--standalone ecl-footer-harmonised__link"
										rel="external noopener noreferrer"
									>
										{t('footer.ec.language-policy')}
									</a>
								</li>
								<li className="ecl-footer-harmonised__list-item">
									<a
										href={`https://ec.europa.eu/info/cookies_${language}`}
										className="ecl-link ecl-link--standalone ecl-footer-harmonised__link"
										rel="external noopener noreferrer"
									>
										{t('footer.ec.cookies')}
									</a>
								</li>
								<li className="ecl-footer-harmonised__list-item">
									<a
										href={`https://ec.europa.eu/info/privacy-policy_${language}`}
										className="ecl-link ecl-link--standalone ecl-footer-harmonised__link"
										rel="external noopener noreferrer"
									>
										{t('footer.ec.privacy-policy')}
									</a>
								</li>
								<li className="ecl-footer-harmonised__list-item">
									<a
										href={`https://ec.europa.eu/info/legal-notice_${language}`}
										className="ecl-link ecl-link--standalone ecl-footer-harmonised__link"
										rel="external noopener noreferrer"
									>
										{t('footer.ec.legal-notice')}
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
}
