import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { withAssetPrefix } from "gatsby";
import { Link, useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import { websiteLanguages } from "utils/website-languages";
import "components/Header.scss";

export default function Header() {
	const { t } = useTranslation();
	const { language, originalPath } = useI18next();
	const [isOpen, setOpen] = useState(false);

	return (
		<>
			<Helmet>
				<body className={isOpen ? 'language-modal-open' : ''} />
			</Helmet>

			<header className="Header ecl-site-header-harmonised ecl-site-header-harmonised--group2">
				<div className="ecl-site-header-harmonised__container ecl-container">
					{/* European Commission */}
					<div className="ecl-site-header-harmonised__top">
						<a href={`https://ec.europa.eu/info/index_${language}`} rel="external noopener noreferrer" className="ecl-link ecl-link--standalone ecl-site-header-harmonised__logo-link">
							<img
								src={withAssetPrefix(`/images/ec-logo/positive/logo-ec--${language}.svg`)}
								className="ecl-site-header-harmonised__logo-image ecl-site-header-harmonised__logo-image-desktop"
								alt={`${t('EC')} logo`}
							></img>
						</a>

						{/* Language toggler */}
						<div className="ecl-site-header-harmonised__action">
							<button
								className="ecl-button ecl-button--ghost ecl-site-header-harmonised__language-selector"
								onClick={() => setOpen(true)}
							>
							<span className="ecl-site-header-harmonised__language-icon">
								<svg className="ecl-icon ecl-icon--s ecl-site-header-harmonised__icon" aria-hidden="true">
									<use xlinkHref={withAssetPrefix("/images/ec-icons.svg#language")}></use>
								</svg>
								<span className="ecl-site-header-harmonised__language-code" aria-hidden="true" >{language}</span>
							</span>
								{websiteLanguages[language]}
							</button>
						</div>
					</div>
				</div>

				{/* Header title */}
				<div className="ecl-site-header-harmonised__banner">
					<div className="ecl-container">
						<p className="ecl-site-header-harmonised__site-name">{t('project-name')}</p>
					</div>
				</div>

				{/* Languages Dropdown */}
				<div className={`ecl-language-list ecl-language-list--overlay ${isOpen ? 'open' : ''}`} role="dialog" aria-modal="true" aria-label="Select your language">
					<div className="ecl-language-list__container ecl-container">
						<div className="ecl-row">
							{/* Close button */}
							<div className="ecl-language-list__close ecl-col-12 ecl-col-l-8 ecl-offset-l-2">
								<button
									className="ecl-button ecl-button--ghost ecl-language-list__close-button"
									onClick={() => setOpen(false)}
								>
								<span className="ecl-button__container">
									<span className="ecl-button__label">Close</span>
									<svg
										className="ecl-icon ecl-icon--s ecl-button__icon ecl-button__icon--after"
										aria-hidden="true"
									>
										<use xlinkHref={withAssetPrefix("/images/ec-icons.svg#close")}></use>
									</svg>
								</span>
								</button>
							</div>

							{/* Modal title */}
							<h1 className="ecl-language-list__title ecl-col-12 ecl-col-l-8 ecl-offset-l-2">
								<svg className="ecl-icon ecl-icon--m ecl-language-list__title-icon" aria-hidden="true">
									<use xlinkHref={withAssetPrefix("/images/ec-icons.svg#generic-lang")}></use>
								</svg>
								Select your language
							</h1>
						</div>

						<div className="ecl-row ecl-language-list__eu">
							<h2 className="ecl-language-list__category ecl-col-12 ecl-col-l-8 ecl-offset-l-2">
								EU official languages
							</h2>
							<div className="ecl-language-list__column ecl-col-12 ecl-col-l-8 ecl-offset-l-2">
								<ul className="ecl-language-list__list">
									{
										Object.entries(websiteLanguages).map(lang => (
											<li key={lang[0]} className={`ecl-language-list__item ${language === lang[0] ? "ecl-language-list__item--is-active" : ""}`}>
												<Link
													to={originalPath}
													lang={lang[0]}
													language={lang[0]}
													hrefLang={lang[0]}
													rel="alternate"
													className="ecl-link ecl-link--standalone ecl-language-list__link"
													onClick={() => setOpen(false)}
												>
													{lang[1]}
													{language === lang[0] && (
														<svg className="ecl-icon ecl-icon--xs ecl-link__icon" aria-hidden="true">
															<use xlinkHref={withAssetPrefix("/images/ec-icons.svg#check")}></use>
														</svg>
													)}
												</Link>
											</li>
										))
									}
								</ul>
							</div>
						</div>
					</div>
				</div>
			</header>
		</>
	);
}
