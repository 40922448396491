
// WEBSITE LANGUAGES
// Please don't change the order.
// It should be the same as in EC Components Library.

const websiteLanguages = {
	"bg": "български",
	"es": "español",
	"cs": "čeština",
	"da": "dansk",
	"de": "Deutsch",
	"et": "eesti",
	"el": "ελληνικά",
	"en": "English",
	"fr": "français",
	"ga": "Gaeilge",
	"hr": "hrvatski",
	"it": "italiano",
	"lv": "latviešu",
	"lt": "lietuvių",
	"hu": "magyar",
	"mt": "Malti",
	"nl": "Nederlands",
	"pl": "polski",
	"pt": "português",
	"ro": "română",
	"sk": "slovenčina",
	"sl": "slovenščina",
	"fi": "suomi",
	"sv": "svenska"
};

exports.websiteLanguages = websiteLanguages;
